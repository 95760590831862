<template>
  <v-container>
    <v-row>
      <v-col></v-col>
      <v-col cols="6">
        <router-view class="" style="margin-top: 10Vh"></router-view>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'PasswordReset',
  mixins: [colorMxn],
  data() {
    return {
    }
  }
}
</script>

<style>
  .banner-light {
   background: #6034BD80;
  }

  .banner-dark {
   background: #C1AFE97D;
  }

  .nevermind-light {
    background: #D1D0D0;
  }

  .nevermind-dark {
    background: #07090D;
  }
</style>